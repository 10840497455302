/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Element
// ==============================================

body,
button,
input,
select,
table,
textarea {
    font-family: $font-family-serif;
    color: $c-text-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
}

a {
    color: $c-link-base;
    text-decoration: none;
}

a:hover {
    color: $c-link-hover;
    text-decoration: none;
}

a:focus,
a:active {
    outline: 0;
    color: $c-link-active;
}

blockquote {
    margin-bottom: $margin-bottom;
    padding: 0.65em 1em;
    background: $c-background;
    font-family: $font-family-special;
    font-size: 24px;
    font-weight: 300;
}

blockquote p:last-child {
    margin-bottom: 0;
}

blockquote cite {
    display: block;
    margin-top: 1em;
    color: $c-text-subtle;
    font-family: $font-family-serif;
    font-size: $font-size-s;

    &::before {
        content: '~ ';
    }
}

dl {
    margin-bottom: $margin-bottom;
}

dt {
    font-weight: bold;
}

dd {
    margin-bottom: ($margin-bottom / 2);
}

p {
    margin: 0 0 $margin-bottom;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
