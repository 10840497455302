/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Stack Layout
// ==============================================

.stack-layout {
    display: flex;
    flex-direction: column;
    padding: 35px 0;
}

.stack-header {
    margin: 0 15px $gap;
}

.stack-header_subtitle {
    color: white(0.6);
}

.stack-header_title {
    color: #FFF;
    font-family: $font-family-sans;
    font-size: 36px;
}

.stack-main {
    //padding: 0 20px;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .stack-header_title {
        font-size: 60px;
    }

}

// ----------------------------------------------

@media (min-width: 1100px) {

    .stack-layout {
        flex: 1;
        flex-direction: row;
    }

    .stack-header {
        flex: 0 0 (15px + 300px);
        // padding-left: 35px;
    }

    .stack-main {
        flex: 1;
        padding: 0;
    }

}
