/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Manual - Table of Contents
// ==============================================

.manual-toc {
    margin-bottom: $gap;
}

.manual-toc .article-title {
    display: inline-block;
    padding: 0.3em 0;
    font-family: $font-family-special;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.2;
}

.manual-toc .current .article-title {
    color: #FFF;
}
