/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-page-header-background: #FB4745 !default;


// ==============================================
// Page Header
// ==============================================

.page-header {
    position: relative;
    border-bottom: 4px solid black(0.15);
    background: $c-page-header-background;
}


// ==============================================
// Header Logo
// ==============================================

.header-logo {
    display: inline-block;
    position: relative;
    height: 48px;
    padding-top: 0.12em;
    padding-right: 20px;
    padding-left: (15px + 26px + 15px);
    color: #FFF;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 48px;

    &:hover {
        background: black(0.15);
        color: #FFF;
    }
}

// ----------------------------------------------

@media (min-width: 480px) {

    .header-logo {
        height: 84px;
        padding-left: (15px + 40px + 15px);
        font-size: 24px;
        line-height: 84px;
    }

}


// ==============================================
// Header Logo
// ==============================================

.header-logo-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    width: 26px;
    margin-top: (29px / -2);
}

// ----------------------------------------------

@media (min-width: 480px) {

    .header-logo-icon {
        width: 40px;
        margin-top: (45px / -2);
    }

}


// ==============================================
// Burger
// ==============================================

.burger {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: (30px / -2);
    padding: 0 8px;
    border: 0;
    border-radius: 2px;
    background: black(0.2);
    color: #FFF;
    font-family: $font-family-special;
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;

    &:hover {
        background: black(0.3);
    }
}

// ----------------------------------------------

@media (min-width: 900px) {

    .burger {
        display: none;
    }

}


// ==============================================
// Nav
// ==============================================

@media (max-width: 899px) {

    .nav {
        position: absolute;
        top: -40px;
        left: 50%;
        z-index: $z-modal-dialog;
        overflow: hidden;
        width: 240px;
        margin-left: -120px;
        padding: 5px;
        border-radius: 2px;
        background: #FFF;

        // Animation properties
        visibility: hidden;
        opacity: 0;
        transition:
            opacity 250ms linear 0ms,
            top 500ms ease-out 0ms,
            visibility 0ms linear 500ms;
    }

    .nav--active .nav {
        visibility: visible;
        top: 40px;
        opacity: 1;
        transition-delay: 0;
    }

}


// ==============================================
// Nav - Level 1
// ==============================================

.nav .ul-1::after {
    @include clearfix;
}

.nav .li-1 {
    position: relative;
}

.nav .a-1 {
    display: block;
    font-family: $font-family-special;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
}
// ----------------------------------------------

@media (max-width: 899px) {

    .nav .li-1 {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .nav .a-1 {
        padding: 10px 20px;
        border-radius: 2px;
        color: black(0.8);
        font-family: $font-family-serif;
        font-weight: bold;
        text-align: center;
        transition: margin 100ms ease-in 0;

        &:hover {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    .nav .section--code       { background: $c-manual-code; }
    .nav .section--design     { background: $c-manual-design; }
    .nav .section--develop    { background: $c-manual-develop; }
    .nav .section--frameworks { background: $c-manual-frameworks; }
    .nav .section--operations { background: $c-manual-operations; }
    .nav .section--playbooks  { background: $c-manual-playbooks; }
    .nav .section--workspace  { background: $c-manual-workspace; }

}

// ----------------------------------------------

@media (min-width: 900px) {

    .nav {
        position: absolute;
        top: 0;
        right: 0;
    }

    .nav .li-1 {
        float: left;
    }

    .nav .li-1:hover .a-1 {
        background: black(0.15);
    }

    .nav .a-1 {
        display: inline-block;
        padding: 0 12px;
        color: #FFF;
        font-size: 19px;
        line-height: 84px;
    }

}

// ----------------------------------------------

@media (min-width: 1050px) {

    .nav .a-1 {
        padding: 0 15px;
        font-size: 22px;
    }

}

// ----------------------------------------------

@media (min-width: 1200px) {

    .nav .a-1 {
        padding: 0 20px;
        font-size: 24px;
    }

}


// ==============================================
// Nav - Level 2
// ==============================================

.nav .ul-2 {
    display: none;
}

// ----------------------------------------------

@media (min-width: 1024px) {

    .nav .ul-2 {
        display: none;

        &::after {
            @include triangle (up, 10px, #FFF);
            top: -10px;
            left: 50%;
            z-index: $z-menu-arrow-fill;
            margin-left: -10px;
        }
    }

    .nav .li-1:hover .ul-2 {
        display: block;
        position: absolute;
        top: (84px - 4px);
        left: 50%;
        z-index: $z-menu;
        width: 190px;
        margin-left: (190px / -2);
        padding: 8px 0;
        border-radius: 2px;
        background: #FFF;
        box-shadow: 0 0 5px black(0.4);
    }

    .nav .a-2 {
        display: block;
        padding: 8px 15px;
        color: $c-text-base;
        font-family: $font-family-sans;
        font-size: 16px;
        line-height: 1.3;

        &:hover {
            background: $c-link-base;
        }
    }

}
