/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Radius
// ==============================================

$radius-l:    4px !default;
$radius-base: 3px !default;
$radius-s:    2px !default;
