/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Stack List
// ==============================================

.stack-list {
    margin: 0 0 20px;
}

.stack-list li {
    margin: 10px 15px;
}

// ----------------------------------------------

@media (min-width: 470px) {

    .stack-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 15px 30px;
    }

    .stack-list li {
        flex: 0 0 200px;
        margin: 10px;
    }

}

// ----------------------------------------------

@media (min-width: 768px) {

    .stack-list li {
        margin: 15px;
    }

}

// ----------------------------------------------

@media (min-width: 1110px) {

    .stack-list {
        margin: 20px 20px 40px;
    }

    .stack-list li {
        flex: 0 0 300px;
        margin: 20px;
    }

}
