/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Stack Cover
// ==============================================

.stack-cover {
    position: relative;
    display: block;
    background: $c-slate;
    box-shadow: 0 2px 5px 0 black(0.3);
    transition: all 75ms ease;
}

.stack-cover:hover {
    box-shadow: 0 6px 15px 0 black(0.3);
    transform: scale(1.04);
}

.stack-cover .stack-title {
    color: black(0.85);
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
}

.stack-cover .stack-number {
    color: black(0.85);
    font-weight: bold;
}

// ----------------------------------------------

@media (max-width: 469px) {

    .stack-cover {
        padding: 12px (15px + 26px + 15px) 12px 15px;
        border-radius: 3px;
    }

    .stack-cover .stack-title {
        margin-bottom: 0;
        padding-top: 0.2em;
    }

    .stack-cover .stack-number {
        display: none;
    }

    .stack-cover .stack-icon {
        position:absolute;
        top: 50%;
        right: 15px;
        width: 26px;
        margin-top: (29px / -2);
    }

}

// ----------------------------------------------

@media (min-width: 470px) {

    .stack-cover {
        width: 200px;
        height: (200px * 1.3);
        padding: 60px 15px 0;
        border-radius: 0 15px 0 0;
        text-align: center;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 6px;
            width: 2px;
            height: 100%;
            background: black(0.07);
        }
    }

    .stack-cover .stack-title {
        margin-bottom: 5px;
        font-size: 28px;
    }

    .stack-cover .stack-number {
        font-size: 16px;
    }

    .stack-cover .stack-icon {
        width: 60px;
        margin: 0 auto 20px;
    }

}

// ----------------------------------------------

@media (min-width: 1110px) {

    .stack-cover {
        width: 300px;
        height: (300px * 1.3);
        padding-top: 80px;
        border-radius: 0 24px 0 0;
        transition: all 150ms ease;

        &::before {
            left: 10px;
            width: 2px;
        }
    }

    .stack-cover .stack-icon {
        width: 90px;
        margin-bottom: 30px;
        filter: drop-shadow(3px 3px 0 black(0.15));
        // Fix "filter" rendering badly on retina screens.
        // See: http://creativeandcode.com/fix-css-filters-creating-blurry-images-on-retina/
        transform: translateZ(0);
    }

    .stack-cover .stack-title {
        margin-bottom: 0.1em;
        font-size: 44px;
    }

    .stack-cover .stack-number {
        font-size: 22px;
    }

}


// ==============================================
// Stack Cover - Variations
// ==============================================

.stack-cover--code       { background:$c-manual-code; }
.stack-cover--design     { background:$c-manual-design; }
.stack-cover--develop    { background:$c-manual-develop; }
.stack-cover--frameworks { background:$c-manual-frameworks; }
.stack-cover--operations { background:$c-manual-operations; }
.stack-cover--playbooks  { background:$c-manual-playbooks; }
.stack-cover--workspace  { background:$c-manual-workspace; }
