/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-footer-background: $c-topography-line !default;


// ==============================================
// Footer
// ==============================================

.page-footer {
    padding:40px 5%;
    border-top:4px solid black(0.15);
    background:$c-footer-background;
}


// ==============================================
// Footer Layout
// ==============================================

.footer-layout {
    display:flex;
    flex-direction:column;
}

.footer-layout-box:first-child {
    margin-bottom:50px;
}

// ----------------------------------------------

@media (min-width: 700px) {

    .footer-layout {
        flex-direction:row;
        justify-content:space-between;
    }

    .footer-layout-box {
        flex:1;
    }

    .footer-layout-box:last-child {
        padding-left:50px;
    }

}


// ==============================================
// Footer About
// ==============================================

.footer-about {
    max-width:36rem;
}

.footer-about h2 {
    @include h4;
    font-family:$font-family-sans;
    color:white(0.5);
    font-size:16px;
    letter-spacing:2px;
    text-transform:uppercase;
}

.footer-about p {
    color:#FFF;
    font-family:$font-family-special;
    font-size:18px;
    font-weight:300;
}

.footer-about p a {
    position:relative;
}

.footer-about p a::after {
    content:'';
    position:absolute;
    top:50%;
    left:0;
    width:100%;
    height:2px;
    margin-top:0.45em;
    background:white(0.1);
}

// ----------------------------------------------

@media (min-width: 480px) {

    .footer-about p {
        font-size: 22px;
    }

}

// ----------------------------------------------

@media (min-width: 768px) {

    .footer-about p {
        font-size: 27px;
    }

}


// ==============================================
// Footer Warn
// ==============================================

.footer-warn {
    max-width: 450px;
}


// ==============================================
// Copyright
// ==============================================

.copyright {
    color: white(0.5);
    font-size: $font-size-xxs;
}
