/* PrismJS 1.16.0
https://prismjs.com/download.html?#themes=prism&languages=markup+css+clike+javascript+bash+markup-templating+php+scss+jsx */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */


// **********************************************
// GravDept:
// Customized and added "kbd" element.
// **********************************************


code,
kbd,
pre {
    color: #202020;
    font-family: $font-family-mono;
    font-size: 13px;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    tab-size: 4;
    hyphens: none;
}

pre::-moz-selection,
pre ::-moz-selection,
code::-moz-selection,
code ::-moz-selection {
    background: #B3D4FC;
}

pre::selection,
pre ::selection,
code::selection,
code ::selection {
    background: #B3D4FC;
}


// ----------------------------------------------
// Code blocks

pre {
    padding: 1em;
    margin-bottom: $margin-bottom;
    border-radius: 2px;
    overflow: auto;
}

:not(pre) > code,
pre {
    border: 1px solid black(0.075);
    background: #E8E8F0;
}


// ----------------------------------------------
// Inline code

kbd,
:not(pre) > code {
    margin: 0 0.05em;
    padding: 0.15em 0.25em;
    border-radius: 0.15em;
    background: #F4F4F8;
}


// ----------------------------------------------
// Syntax

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.punctuation {
    color: #999;
}

.namespace {
    opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
    color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.builtin {
    color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #A67F59;
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #07A;
}

.token.function,
.token.class-name {
    color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
    color: #E90;
}

.token.important,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}
