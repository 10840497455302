/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Article List
// ==============================================

.article-list li {
    margin-bottom: 20px;
}


// ----------------------------------------------
// Article Link

.article-link {
    display: inline-block;
}

.article-link .article-title {
    font-family: $font-family-special;
    font-size: 36px;
    font-weight: 300;
    line-height: 1.2;
}

.article-link .article-description {
    margin-top: 10px;
    font-size: 14px;
}
