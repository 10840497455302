/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Units
// ==============================================

// Hex is the percentage difference between 101010 and 202020.
// Used with Sass functions darken() and lighten().
$hex: 6.1% !default;


// ==============================================
// Named Colors
// ==============================================

// Named colors are rarely used in other partials. They establish the standard
// palette to be referenced from other variables in this file.

// Primary + secondary colors
$c-blue:         #3498DB !default;
$c-brown:        #A87848 !default;
$c-green:        #2ECC71 !default;
$c-orange:       #FF7300 !default;
$c-purple:       #9B59B6 !default;
$c-red:          #FB4745 !default;
$c-yellow-base:  #ECDA00 !default;
$c-yellow-light: $c-library-yellow-0 !default;

// Fancy colors
$c-cream:  #FAF0E6    !default;
$c-peach:  #FA8       !default;
$c-slate:  #BDC3C7    !default;
$c-turquoise: #16A085 !default;

// Manual cover colors
$c-manual-code:       $c-green       !default;
$c-manual-design:     $c-purple      !default;
$c-manual-develop:    $c-brown       !default;
$c-manual-frameworks: $c-blue        !default;
$c-manual-operations: $c-orange      !default;
$c-manual-playbooks:  $c-red         !default;
$c-manual-workspace:  $c-yellow-base !default;

// Pattern colors
$c-topography:      #3A3C44 !default;
$c-topography-line: #30323C !default;


// ==============================================
// UI State Colors
// ==============================================

$c-danger:   $c-red         !default;
$c-help:     $c-purple      !default;
$c-info:     $c-blue        !default;
$c-security: #E0F2FF        !default;
$c-success:  $c-green       !default;
$c-warn:     $c-yellow-base !default;


// ==============================================
// Tone Colors
// ==============================================

// Tone colors set the mood. The default tone defines the standard for
// backgrounds and borders of most elements. Other tones attract/divert
// attention relative to the default tone. Use these variables liberally in
// partials to keep the UI consistent.

// ----------------------------------------------
// Default - Baseline for most elements.

$c-background: #F4F4F4 !default;
$c-border:     #C0C0C0 !default;

// ----------------------------------------------
// Contrast - Opposite of the default tone (light/dark).

$c-contrast-background: #505054 !default; // Tint blue +4
$c-contrast-border:     darken($c-contrast-background, 10%) !default;

// ----------------------------------------------
// Emphasis - Like the default tone but more priority.

$c-emphasis-background: #E8E8E8 !default;
$c-emphasis-border:     #C0C0C0 !default;

// ----------------------------------------------
// Help - Distinguish "help" elements from other call-to-action colors.

$c-help-background: $c-purple !default;
$c-help-border:     darken($c-purple, 10%) !default;

// ----------------------------------------------
// Offset

$c-offset-background: #808084 !default; // Tint blue +4
$c-offset-border:     darken($c-offset-background, 10%) !default;

// ----------------------------------------------
// Priority - Highlight with a color that stands out from the default tone.

$c-priority-background: $c-yellow-light !default;

// ----------------------------------------------
// Subtle - Like the default tone but less priority.

$c-subtle-background: #FBFBFB !default;
$c-subtle-border:     #EBEBEB !default;


// ==============================================
// Action Colors
// ==============================================

// Highest priority
$c-action-danger-base:   $c-danger             !default;
$c-action-danger-hover:  darken($c-danger, 5%) !default;
$c-action-danger-focus:  darken($c-danger, 5%) !default;
$c-action-danger-active: darken($c-danger, 5%) !default;

// High priority
$c-action-primary-base:   $c-green             !default;
$c-action-primary-hover:  darken($c-green, 5%) !default;
$c-action-primary-focus:  darken($c-green, 5%) !default;
$c-action-primary-active: darken($c-green, 5%) !default;

// Default priority
$c-action-default-base:   $c-green             !default;
$c-action-default-hover:  darken($c-green, 5%) !default;
$c-action-default-focus:  darken($c-green, 5%) !default;
$c-action-default-active: darken($c-green, 5%) !default;

// Low priority
$c-action-secondary-base:   $c-slate             !default;
$c-action-secondary-hover:  darken($c-slate, 5%) !default;
$c-action-secondary-focus:  darken($c-slate, 5%) !default;
$c-action-secondary-active: darken($c-slate, 5%) !default;

// Lowest priority
$c-action-disabled-base: $c-background !default;
$c-action-disabled-text: #A4A4A8       !default;

// Help
$c-action-help-base:   $c-help-background             !default;
$c-action-help-hover:  darken($c-help-background, 4%) !default;
$c-action-help-focus:  darken($c-help-background, 4%) !default;
$c-action-help-active: darken($c-help-background, 4%) !default;


// ==============================================
// Input Colors
// ==============================================

$c-input-border:      #C0C0C0          !default;
$c-input-focus-base:  #B4DEFA          !default;
$c-input-focus-error: $c-library-red-2 !default;


// ==============================================
// Link Colors
// ==============================================

$c-link-base:    $c-green             !default;
$c-link-hover:   darken($c-green, 5%) !default;
$c-link-focus:   darken($c-green, 5%) !default;
$c-link-active:  darken($c-green, 5%) !default;
$c-link-visited: $c-green             !default;

// $c-link-contrast-base:   lighten($c-link, 20%) !default;
// $c-link-contrast-hover:  lighten($c-link, 10%) !default;
// $c-link-contrast-focus:  lighten($c-link, 10%) !default;
// $c-link-contrast-active: lighten($c-link, 20%) !default;


// ==============================================
// Text Colors
// ==============================================

$c-text-base:     #505050    !default;
$c-text-danger:   $c-danger  !default;
$c-text-subtle:   #A0A0A0    !default;
$c-text-success:  $c-success !default;
$c-text-disabled: #A4A4A8    !default; // Tint blue +4

$c-text-contrast-base:   white(0.9) !default;
$c-text-contrast-subtle: white(0.7) !default;
