/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Matter
// ==============================================

.page-matter {
    display: flex; // Allow child to fill vertical space
    flex-direction: column;
    flex: 1; // Expand to fill vertical space between header/footer
}
