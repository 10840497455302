/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Manual Cover
// ==============================================

.manual-cover {
    position: relative;
    display: block;
    transition: all 75ms ease;
}

// ----------------------------------------------

@media (max-width: 499px) {

    .manual-cover {
        min-height:(50px * 1.3);
        padding: 24px 0 0 (50px + 15px);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            height: (50px * 1.3);
            border-radius: 0 8px 0 0;
            box-shadow: 0 2px 5px 0 black(0.3);
        }
    }

}

// ----------------------------------------------

@media (min-width: 500px) {

    .manual-cover {
        position: relative;
        display: block;
        width: 200px;
        height: (200px * 1.3);
        padding: 70px 18px 0 28px;
        border-radius: 0 15px 0 0;
        box-shadow: 0 2px 5px 0 black(0.3);
        background: $c-slate;
        transition: all 75ms ease;

        &:hover {
            box-shadow: 0 6px 15px 0 black(0.3);
            transform: scale(1.04);
        }

        // Vertical binding line
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 6px;
            width: 2px;
            height: 100%;
            background: black(0.07);
        }
    }

}


// ==============================================
// Manual Cover - Variations
// ==============================================

@media (max-width: 499px) {

    .manual-cover--code::before       { background: $c-manual-code; }
    .manual-cover--design::before     { background: $c-manual-design; }
    .manual-cover--develop::before    { background: $c-manual-develop; }
    .manual-cover--frameworks::before { background: $c-manual-frameworks; }
    .manual-cover--operations::before { background: $c-manual-operations; }
    .manual-cover--playbooks::before  { background: $c-manual-playbooks; }
    .manual-cover--workspace::before  { background: $c-manual-workspace; }

}

// ----------------------------------------------

@media (min-width: 500px) {

    /* stylelint-disable function-comma-space-after */
    .manual-cover--code       { background: linear-gradient(to bottom, $c-manual-code,       $c-manual-code       80%, #FFF 80%, #FFF); }
    .manual-cover--design     { background: linear-gradient(to bottom, $c-manual-design,     $c-manual-design     80%, #FFF 80%, #FFF); }
    .manual-cover--develop    { background: linear-gradient(to bottom, $c-manual-develop,    $c-manual-develop    80%, #FFF 80%, #FFF); }
    .manual-cover--frameworks { background: linear-gradient(to bottom, $c-manual-frameworks, $c-manual-frameworks 80%, #FFF 80%, #FFF); }
    .manual-cover--operations { background: linear-gradient(to bottom, $c-manual-operations, $c-manual-operations 80%, #FFF 80%, #FFF); }
    .manual-cover--playbooks  { background: linear-gradient(to bottom, $c-manual-playbooks,  $c-manual-playbooks  80%, #FFF 80%, #FFF); }
    .manual-cover--workspace  { background: linear-gradient(to bottom, $c-manual-workspace,  $c-manual-workspace  80%, #FFF 80%, #FFF); }
    /* stylelint-enable */

}


// ==============================================
// Manual Cover - Content
// ==============================================

@media (max-width: 499px) {

    .manual-cover .manual-title {
        color: white(1);
        font-size: 20px;
    }

    .manual-cover .manual-subtitle {
        display: none;
    }

    .manual-cover .manual-stack {
        position: absolute;
        top: 0.5em;
        color: white(0.5);
        font-family: $font-family-sans;
        font-size: 13px;
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;
    }

    .manual-cover .manual-icon {
        position: absolute;
        top: 16px; // (65 - 32) / 2;
        left: 11px;
        width: 28px;
    }

}

// ----------------------------------------------

@media (min-width: 500px) {

    .manual-cover .manual-title {
        color: black(0.8);
        font-size: 26px;
        font-weight: bold;
        line-height: 1.2;
    }

    .manual-cover .manual-subtitle {
        position: absolute;
        bottom: 17px;
        color: #808080;
        font-size: 13px;
    }

    .manual-cover .manual-stack {
        position: absolute;
        top: 48px;
        color: black(0.6);
        font-family: $font-family-sans;
        font-size: 13px;
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;
    }

    .manual-cover .manual-icon {
        position: absolute;
        top: 10px;
        right: 12px;
        width: 28px;
    }

}
