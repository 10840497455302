/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Font Family
// ==============================================

$font-family-mono:    'Source Code Pro', menlo, monaco, consolas, 'Courier New', monospace !default;
$font-family-sans:    'ff-meta-web-pro', 'Helvetica Neue', helvetica, arial, sans-serif !default;
$font-family-serif:   'ff-meta-serif-web-pro', 'Georgia', serif !default;
$font-family-special: 'brandon-grotesque', helvetica, arial, sans-serif !default;

$font-family-base: $font-family-serif !default;


// ==============================================
// Font Size
// ==============================================

$font-size-xxxxl: 48px !default;
$font-size-xxxl:  32px !default;
$font-size-xxl:   24px !default;
$font-size-xl:    20px !default;
$font-size-l:     18px !default;

$font-size-base:  17px !default;

$font-size-s:     15px !default;
$font-size-xs:    13px !default;
$font-size-xxs:   12px !default;
$font-size-xxxs:  11px !default;


// ==============================================
// Line Height
// ==============================================

$line-height-base: 1.5 !default;

$line-height-s:   1.4 !default;
$line-height-xs:  1.3 !default;
$line-height-xxs: 1.2 !default;


// ==============================================
// Margin Bottom
// ==============================================

$margin-bottom: 1.5em !default;
