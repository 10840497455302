/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


/* stylelint-disable declaration-no-important */


// ==============================================
// Border
// ==============================================

.b-base { border: 1px solid $c-border !important; }

.b-0 { border: 0 !important; }

.b-t-0 { border-top:    0 !important; }
.b-r-0 { border-right:  0 !important; }
.b-b-0 { border-bottom: 0 !important; }
.b-l-0 { border-left:   0 !important; }
