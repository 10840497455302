/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Global Layout
// ==============================================

html,
body {
    height: 100%;
}

body {
    // background: url(../../img/build/png/topography-1000.png) fixed $c-topography;
    background: url(../../img/build/png/topography-750.png) fixed $c-topography;
    // background: url(../../img/build/png/topography-500.png) fixed $c-topography;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
