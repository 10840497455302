/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


/* stylelint-disable declaration-no-important */


// ==============================================
// Width
// ==============================================

.width-auto { width: auto !important; }

.width-100 { width: 100% !important; }
.width-75  { width: 75%  !important; }
.width-50  { width: 50%  !important; }
.width-25  { width: 25%  !important; }
