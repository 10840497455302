/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Article
// ==============================================

.article blockquote,
.article dl,
.article h1,
.article h2,
.article h3,
.article h4,
.article ol,
.article p,
.article pre,
.article ul,
.article [class*='note'] {
    max-width: 45rem;
}

.article h2 {
    @include h2;
    margin-top: 1.75em;
    padding-top: 1.25em;
    border-top: 1px solid $c-border;
}

.article h3 {
    @include h3;
    margin-top: 1.5em;
}

.article h4 {
    @include h4;
}

.article ol {
    margin-bottom: $margin-bottom;
}

.article ol li {
    list-style: decimal outside;
    margin: 0 0 0.5em 2em;

    &:last-child {
        margin-bottom: 0;
    }
}

.article ul {
    margin-bottom: $margin-bottom;
}

.article ul li {
    list-style: disc outside;
    margin: 0 0 0.5em 2em;

    &:last-child {
        margin-bottom: 0;
    }
}

.article pre,
.article pre code {
    font-size: $font-size-xs;
}

.article *:not(pre) code {
    font-size: 0.8em;
}

.article .twitter-tweet {
    /* stylelint-disable declaration-no-important */
    margin-top: 0 !important;
    margin-bottom: $margin-bottom !important;
    /* stylelint-enable */
}
