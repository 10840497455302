/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Mixin - Focus
// ==============================================

@mixin focus {
    box-shadow: 0 0 0 3px $c-input-focus-base;
}

@mixin focus-error {
    box-shadow: 0 0 0 3px $c-input-focus-error;
}
