/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Manual
// ==============================================

.manual-description {
    color: #FFF;
    font-family: $font-family-special;
    font-size: 32px;
    font-weight: 300;
}

.manual-toc-heading {
    margin-bottom: 30px;
    color: #FFF;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.manual-sidebar .manual-cover {
    float: right;
}


// ==============================================
// Manual Layout
// ==============================================

.manual-layout {
    display: flex;
    flex-direction: column;
    padding: 35px 0;
}

.manual-main {
    padding: 0 20px;
    //background: white(0.2);
}

.manual-sidebar {
    order: -1;
    color: #FFF;
}

// ----------------------------------------------

@media (min-width: 520px) {

    .manual-layout {
        flex: 1;
        flex-direction: row;
        padding: 60px;
    }

    .manual-main {
        flex: 1;
        padding: 0;
    }

    .manual-sidebar {
        flex: 0 0 30%;
        padding-right: 70px;
        padding-left: 35px;
    }

}
