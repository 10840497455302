/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


/* stylelint-disable declaration-no-important */


// ==============================================
// Max Width
// ==============================================

.max-width-auto { max-width: auto !important; }

.max-width-800 { max-width: 800px !important; }
.max-width-400 { max-width: 400px !important; }
