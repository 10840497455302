/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Note
// ==============================================

.note {
    margin-bottom: $margin-bottom;
    padding: 15px;
    border-left: 5px solid black(0.075);
    background: #E8E8F0;
    font-size: $font-size-s;
}

// ----------------------------------------------
// Modifier: Danger

.note--danger {
    border-left-color: $c-danger;
    background: lighten($c-danger, 30%);
}

// ----------------------------------------------
// Modifier: Warn

.note--warn {
    border-left-color: #FBE983;
    background: lighten(#FBE983, 15%);
}


// ==============================================
// Note - Misc.
// ==============================================

.note > *:last-child {
    margin-bottom: 0;
}
