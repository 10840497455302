/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Article Layout
// ==============================================

.article-layout {
    display: flex;
    flex-direction: column;
}

.article-main {
    padding: 35px 20px;
    background: #FFF;
}

.article-sidebar {
    order: -1;
    color: #FFF;
}

// ----------------------------------------------

@media (min-width: 900px) {

    .article-layout {
        flex: 1;
        flex-direction: row;
    }

    .article-main {
        flex: 1;
        padding: 35px 45px;
    }

    .article-sidebar {
        flex: 0 0 30%;
        padding: 35px;
    }

    .article-sidebar-nav {
        float: right;
        width: 200px;
    }

}

// ----------------------------------------------

@media (min-width: 1100px) {

    .article-main {
        padding: 40px 70px;
    }

    .article-sidebar {
        padding: 35px 70px;
    }

}


// ==============================================
// Article Main
// ==============================================

// ----------------------------------------------
// Article Header

.article-header h1 {
    @include h1;
}

// ----------------------------------------------
// Inter Article Nav

.inter-article-nav {
    display: flex;
    flex-direction: column;
    background: $c-red;
}

[class*='nav-item'] {
    position: relative;
    flex: 1;
    padding: 12px 20px 15px;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
a[class*='nav-item']:hover {
    background: black(0.15);
}

[class*='nav-item'] .label {
    color: white(0.65);
    font-size: 12px;
    text-transform: uppercase;
}

[class*='nav-item'] .value {
    color: #FFF;
    font-family: $font-family-special;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.2;
}

[class*='nav-item'].disabled::before,
[class*='nav-item'].disabled .label,
[class*='nav-item'].disabled .value {
    opacity: 0.4;
}

// Previous

.nav-item-prev {
    padding-right: (20px + 19px + 20px);
    text-align: right;
}

.nav-item-prev::before {
    content: '';
    position: absolute;
    top: 21px;
    right: 20px;
    width: 19px;
    height: 32px;
    background: url(../../img/build/svg/arrow-left.svg) no-repeat;
}

// Next

.nav-item-next {
    order: -1;
    padding-left: (20px + 19px + 20px);
    border-bottom: 1px solid white(0.5);
}

.nav-item-next::before {
    content: '';
    position: absolute;
    top: 21px;
    left: 20px;
    width: 19px;
    height: 32px;
    background: url(../../img/build/svg/arrow-right.svg) no-repeat;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .inter-article-nav {
        flex-direction: row;
    }

    .nav-item-prev {
        flex: 1;
    }

    .nav-item-next {
        flex: 1;
        order: 0;
        border-bottom: 0;
        border-left: 1px solid white(0.5);
    }

}

// ----------------------------------------------

@media (min-width: 900px) {

    .nav-item-prev {
        flex: 0 0 30%;
    }

    .nav-item-next {
        flex: 1;
    }

}


// ==============================================
// Article Sidebar
// ==============================================

.article-sidebar .manual-cover {
    margin-bottom: 30px;
}

// ----------------------------------------------

@media (max-width: 899px) {

    .article-sidebar [data-toggle] {
        position: relative;
        display: block;
        padding: 15px 20px 15px (20px + 20px);
        background: $c-action-primary-base;
        color: #FFF;
        font-family: $font-family-special;
        font-size: 18px;
        font-weight: 300;

        &:hover {
            background: $c-action-primary-hover;
        }

        &::before {
            @include triangle (down, 5px, #FFF);
            left: 20px;
            top: 50%;
            margin-top: (5px / -2);
        }
    }

    .article-sidebar [data-toggle-target='show'] {
        display: block;
    }

    .article-sidebar [data-toggle-target='hide'] {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: 900px) {

    .article-sidebar [data-toggle] {
        display: none;
    }

}
