/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Home
// ==============================================

.home-header {
    margin: 4.5vw 0 5.5vw;
}

.site-title {
    margin: 0;
    color: #FFF;
    font-family: $font-family-special;
    font-size: 8.5vw;
    line-height: 1;
    letter-spacing: 0.1em;
    text-align: center;
    text-shadow: 7px 7px 0 black(0.15);
    text-transform: uppercase;
}

.site-title img {
    display: inline;
    width: 8.5vw;
    margin-right: 7px;
    vertical-align: sub;
    filter: drop-shadow(7px 7px 0 black(0.15));
    // Fix "filter" rendering badly on retina screens.
    // See: http://creativeandcode.com/fix-css-filters-creating-blurry-images-on-retina/
    transform: translateZ(0);
}
