/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Page Title
// ==============================================

.page-title {
    margin: 0.6em 15px;
    color: #FFF;
    font-family: $font-family-special;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: 0.1em;
    line-height: 1.1;
    text-align: center;
    text-shadow: 3px 3px 0 black(0.15);
    text-transform: uppercase;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .page-title {
        font-size: 72px;
    }

}
