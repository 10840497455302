/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-h1: #404040 !default;
$c-h2: #404040 !default;
$c-h3: #404040 !default;
$c-h4: #404040 !default;
$c-h5: #404040 !default;
$c-h6: #404040 !default;


// ==============================================
// Mixin - Typography
// ==============================================

@mixin h1 {
    margin-bottom: 0.6em;
    color: $c-h1;
    font-family: $font-family-sans;
    font-size: 37px;
    font-weight: bold;
    font-style: normal;
    line-height: $line-height-xxs;
    text-transform: none;
}

@mixin h2 {
    margin-bottom: 0.75em;
    color: $c-h2;
    font-family: $font-family-sans;
    font-size: 25px;
    font-weight: bold;
    font-style: normal;
    line-height: $line-height-xxs;
    text-transform: none;
}

@mixin h3 {
    margin-bottom: 0.75em;
    color: $c-h3;
    font-family: $font-family-sans;
    font-size: 19px;
    font-weight: bold;
    font-style: normal;
    line-height: $line-height-s;
    text-transform: none;
}

@mixin h4 {
    margin-bottom: 10px;
    color: $c-h4;
    font-family: $font-family-sans;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: $line-height-s;
    text-transform: none;
}

@mixin h5 {
    margin-bottom: 10px;
    color: $c-h5;
    font-family: $font-family-sans;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: $line-height-s;
    text-transform: uppercase;
}

@mixin h6 {
    margin-bottom: 5px;
    color: $c-h6;
    font-family: $font-family-sans;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: $line-height-s;
    text-transform: uppercase;
}

@mixin text {
    margin-bottom: 0;
    color: $c-text;
    font-family: $font-family-sans;
    font-size: $font-size;
    font-weight: normal;
    font-style: normal;
    line-height: $line-height-base;
    text-transform: none;
}
