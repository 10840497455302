/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Manual List
// ==============================================

.manual-list {
    margin: 0 15px 30px;
}

.manual-list li {
    margin-bottom: 15px;
}

// ----------------------------------------------

@media (min-width: 500px) {

    .manual-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .manual-list li {
        flex: 0 0 200px;
        margin: 0 15px 30px;
    }

}


// ==============================================
// Variation: Left
// ==============================================

@media (min-width: 500px) {

    .manual-list--left {
        justify-content: flex-start;
    }

}
