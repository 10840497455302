/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Breadcrumbs
// ==============================================

.breadcrumbs {
    overflow-x: scroll;
    padding: 10px 15px;
    border-bottom: 2px solid black(0.05);
    background: $c-topography-line;
    color: #FFF;
    font-family: $font-family-special;
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;
}

.breadcrumbs li {
    display: inline-block;
    margin-right: 7px;

    &:last-child {
        display: none;
    }
}

// ----------------------------------------------

@media (min-width: 480px) {

    .breadcrumbs {
        padding: 15px 20px;
        font-size: 18px;
    }

}
