/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Lead
// ==============================================

.lead {
    margin-bottom: 1em;
    font-family: $font-family-special;
    font-size: 27px;
    line-height: 1.25;
}

.lead code {
    color: inherit;
}
