/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Headings
// ==============================================

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: inherit;
    font-weight: normal;
    font-style: normal;
}

.h1 { @include h1; }
.h2 { @include h2; }
.h3 { @include h3; }
.h4 { @include h4; }
.h5 { @include h5; }
.h6 { @include h6; }
