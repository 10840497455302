/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Overlay
// ==============================================

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-modal-backdrop;
    background: #000;

    // Animation properties
    visibility: hidden;
    opacity: 0;
    transition:
        opacity 300ms ease-out 0ms,
        visibility 0ms linear 300ms;
}

.overlay--active .overlay {
    transition-delay: 0;
    visibility: visible;
    opacity: 0.825;
}
