/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$container-max-width: $trim-base + 1170 + $trim-base !default;


// ==============================================
// Container
// ==============================================

.container {
    position: relative;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0 $trim-base;

    &::after {
        @include clearfix;
    }
}
