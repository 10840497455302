/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright  Copyright 2016 Gravity Department
*/


// ==============================================
// Form
// ==============================================

[type='checkbox'],
[type='radio'] {
    margin-right: 0.5em;
}
