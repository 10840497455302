/**
* GravDept Field Manuals
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Related Manuals
// ==============================================

.related-manuals {
    margin: 60px 0 30px;
}

.related-manuals h1 {
    @include h2;
    margin-bottom: 1.25em;
    color: #FFF;
    text-align: center;
}
